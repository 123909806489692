import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';

const { spacing } = lightTheme;

const styles: Record<string, SxProps> = {
  bottomBtnBox: {
    marginTop: 'auto',
    marginBottom: 0,
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: spacing(1),
    a: {
      textDecoration: 'none',
      button: {
        textTransform: 'none'
      }
    },
    button: {
      textTransform: 'none'
    }
  }
};

export default styles;
