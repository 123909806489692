// libs
import React from 'react';
import { Box } from '@mui/material';
import type { SxProps } from '@mui/material';
import Link from 'next/link';
// src
import { Button, LibButtonProps } from '../Button';
// styles
import styles from './styles';

export interface LibButtonBoxWrapperProps {
  sx?: SxProps;
  btnPrimary?: null | {
    label: React.ReactNode;
    href: string;
    colorProp?: 'primary' | 'secondary' | 'tertiary' | 'text';
    variant?: LibButtonProps['variant'];
    size?: 'large' | 'medium' | 'small';
    target?: string;
    isLink?: boolean;
  };
  btnSecondary?: null | {
    label: React.ReactNode;
    href: string;
    colorProp?: 'primary' | 'secondary' | 'tertiary' | 'text';
    variant?: LibButtonProps['variant'];
    size?: 'large' | 'medium' | 'small';
    target?: string;
    isLink?: boolean;
  };
  btnTertiary?: null | {
    label: React.ReactNode;
    href: string;
    colorProp?: 'primary' | 'secondary' | 'tertiary' | 'text';
    variant?: LibButtonProps['variant'];
    size?: 'large' | 'medium' | 'small';
    target?: string;
    isLink?: boolean;
  };
}

function LibButtonBoxWrapper({
  btnPrimary,
  btnSecondary,
  btnTertiary,
  sx = {}
}: LibButtonBoxWrapperProps) {
  return (
    <Box sx={{ ...styles.bottomBtnBox, ...sx } as SxProps}>
      {btnPrimary && (
        <Box>
          {btnPrimary.isLink ? (
            <Link
              prefetch={false}
              href={btnPrimary?.href || ''}
              target={btnPrimary?.target || ''}
            >
              <Button
                color="primary"
                variant={btnPrimary.colorProp || btnPrimary.variant}
                size={btnPrimary.size || 'medium'}
              >
                {btnPrimary.label}
              </Button>
            </Link>
          ) : (
            <Button
              color="primary"
              variant={btnPrimary.colorProp || btnPrimary.variant}
              size={btnPrimary.size || 'medium'}
            >
              {btnPrimary.label}
            </Button>
          )}
        </Box>
      )}
      {btnSecondary && (
        <Box>
          {btnSecondary.isLink ? (
            <Link
              prefetch={false}
              href={btnSecondary?.href || ''}
              target={btnSecondary?.target || ''}
            >
              <Button
                color="primary"
                variant={btnSecondary.colorProp || btnSecondary.variant}
                size={btnSecondary.size || 'medium'}
              >
                {btnSecondary.label}
              </Button>
            </Link>
          ) : (
            <Button
              color="primary"
              variant={btnSecondary.colorProp || btnSecondary.variant}
              size={btnSecondary.size || 'medium'}
            >
              {btnSecondary.label}
            </Button>
          )}
        </Box>
      )}
      {btnTertiary && (
        <Box>
          {btnTertiary.isLink ? (
            <Link
              prefetch={false}
              href={btnTertiary?.href || ''}
              target={btnTertiary?.target || ''}
            >
              <Button
                color="primary"
                variant={btnTertiary.colorProp || btnTertiary.variant}
                size={btnTertiary.size || 'medium'}
              >
                {btnTertiary.label}
              </Button>
            </Link>
          ) : (
            <Button
              color="primary"
              variant={btnTertiary.colorProp || btnTertiary.variant}
              size={btnTertiary.size || 'medium'}
            >
              {btnTertiary.label}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}

export const ButtonBoxWrapper = React.memo(LibButtonBoxWrapper);
