import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';

const { palette, spacing, containers } = lightTheme;

const baseStyles = {
  ...containers.type2,
  maxWidth: '100%',
  display: {
    xs: 'block',
    md: 'flex'
  },
  flexDirection: {
    xs: 'column-reverse',
    md: 'row-reverse'
  }
};

const containerText = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: {
    xs: 'flex-start',
    md: 'center'
  },
  minHeight: '100%',
  padding: {
    xs: spacing(1),
    md: 0
  }
};

const textTitle = {
  overflowWrap: 'break-word',
  color: palette.primary.main,
  marginBottom: spacing(1)
};

const subtitleText = {
  color: palette.grey.main,
  width: {
    xs: '100%',
    md: '98%',
    lg: '80%'
  },
  marginBottom: spacing(1),
  textTransform: 'none',
  whiteSpace: 'pre-wrap'
};

const styles: Record<string, SxProps> = {
  redGradient: {
    flexDirection: {
      xs: 'column-reverse',
      md: 'row-reverse'
    },
    background: palette.gradient['500'],
    backgroundClip: 'content-box'
  },

  sectionContent: baseStyles,

  btnBox: {
    marginTop: spacing(2),
    gap: '2rem'
  },

  // Text Container Layout
  textContainer: {
    ...containerText
  },

  // Title Text (Heading: h1, h2)
  titleVariantBlack: {
    ...textTitle,
    color: palette.grey.main
  },

  // Subtitle text (Heading: h3...)
  subtitle: {
    ...subtitleText
  },

  // Italic text
  italicText: {
    lineHeight: '1.5rem',
    color: palette.grey.main,
    marginBottom: spacing(1),
    whiteSpace: 'pre-wrap'
  },

  anchorText: {
    color: palette.primary.main
  },

  // Image container
  bkImgredGradient: {
    display: 'flex',
    justifyContent: 'center',
    height: {
      xs: '16rem',
      md: 'inherit'
    },
    '& div': {
      display: 'flex',
      justifyContent: 'center'
    },
    '& img': {
      objectFit: 'contain',
      width: 'auto',
      height: {
        xs: '20rem',
        md: 'inherit'
      },
      maxWidth: {
        md: '22rem'
      }
    }
  }
};

export default styles;
