import React from 'react';
import Link from 'next/link';
import { Typography, Box, Grid } from '@mui/material';
import Markdown from 'markdown-to-jsx';
// src
import { ButtonBoxWrapper } from '../../components/ButtonBoxWrapper/index';
import { NextImage } from '../../components/NextImage/index';
import Button, { LibButtonProps } from '../../components/Button';
// styles&utils
import markdownOverrides from '../../utils/MarkdownOverrides';
import styles from './styles';

export interface LibConveyanceSectionProps {
  richTextContent?: string;
  sideImg: {
    src: string;
    alt: string;
  };
  buttons?: {
    label: string;
    href: string;
    target?: string;
    variant?: LibButtonProps['variant'];
  }[];
  btnPrimary?: {
    label: string;
    href: string;
    target?: string;
    variant?: LibButtonProps['variant'];
    size?: 'large' | 'medium' | 'small';
    isLink?: boolean;
  };
  btnSecondary?: {
    label: string;
    href: string;
    target?: string;
    variant?: LibButtonProps['variant'];
    size?: 'large' | 'medium' | 'small';
    isLink?: boolean;
  };
}

function LibConveyanceSection({
  richTextContent,
  buttons,
  sideImg,
  btnPrimary,
  btnSecondary
}: LibConveyanceSectionProps) {
  const overrides = {
    ...markdownOverrides,
    h1: {
      component: Typography,
      props: { variant: 'h1', sx: styles.titleVariantBlack }
    },
    h2: {
      component: Typography,
      props: { variant: 'h2', sx: styles.titleVariantBlack }
    },
    h3: {
      component: Typography,
      props: { variant: 'h3', sx: styles.subtitle }
    },
    h4: {
      component: Typography,
      props: { variant: 'h4', sx: styles.italicText }
    },
    h5: {
      component: Typography,
      props: { variant: 'h5' }
    },
    h6: {
      component: Typography,
      props: { variant: 'h6' }
    },
    p: {
      component: Typography,
      props: {
        variant: 'body1',
        sx: styles.italicText
      }
    },
    a: {
      component: Link,
      props: { sx: styles.anchorText }
    }
  };

  return (
    <Box component="section">
      <Box sx={styles.redGradient}>
        <Grid container sx={styles.sectionContent}>
          <Grid item xs={12} md={6}>
            <Box sx={styles.bkImgredGradient}>
              <NextImage
                image={{ src: sideImg?.src, alt: sideImg?.alt }}
                sizes="50vw"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={styles.textContainer}>
            {richTextContent && (
              <Markdown options={{ overrides }}>{richTextContent}</Markdown>
            )}
            <Box sx={styles.btnBox}>
              {buttons?.length &&
                buttons.map((btn) => (
                  <Link
                    key={btn?.href}
                    prefetch={false}
                    href={btn?.href || ''}
                    target={btn?.target || '_self'}
                  >
                    <Button variant={btn?.variant || 'primary-dark'}>
                      {btn?.label || 'primary'}
                    </Button>
                  </Link>
                ))}
              {(btnPrimary || btnSecondary) && !buttons?.length && (
                <Box sx={styles.btnWrapperBox}>
                  <ButtonBoxWrapper
                    btnPrimary={btnPrimary}
                    btnSecondary={btnSecondary}
                  />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export const ConveyanceSection = React.memo(LibConveyanceSection);

export default ConveyanceSection;
